import * as searchListeners from './listeners/search'
import * as bookingListeners from './listeners/booking'
import * as productsListeners from './listeners/products'
import * as sharedListeners from './listeners/shared'
import * as bookingGateListeners from './listeners/booking-gate'
import * as quickSearchListeners from './listeners/quick-search'
import * as vouchersListeners from './listeners/vouchers'
import * as paymentsListeners from './listeners/payments'
import * as liveSearchListeners from './listeners/live-search'
import * as reviewsListListeners from './listeners/reviews-list'
import * as reviewsTeaserListeners from './listeners/reviews-teaser'
import * as accountBookingListeners from './listeners/account-booking'
import * as accountBookingsListeners from './listeners/account-bookings'
import * as accountBookedServiceEditListeners from './listeners/account-booked-service-edit'
import * as refundsListeners from './listeners/refunds'
import * as faqListeners from './listeners/faq'
import * as favoriteListeners from './listeners/favorites'
import * as navigationListeners from './listeners/nav'
import * as personalDetailsEditListeners from './listeners/personal-details-edit'
import * as loginFormListeners from './listeners/login-form'
import * as rebookingOptionsListeners from './listeners/rebooking-options'
import * as preferenceQuestionnaireListeners from './listeners/preference-questionnaire'
import * as profileDataListeners from './listeners/profile-data'
import * as accommodationRoomtypesListeners from './listeners/accommodation-roomtypes'
import * as accommodationTabsListeners from './listeners/accommodation-tabs'
import * as accommodationFacilitiesListeners from './listeners/accommodation-facilities'
import * as accommodationMealplansListeners from './listeners/accommodation-mealplans'
import * as accommodationDestinationListeners from './listeners/accommodation-destination'
import * as accommodationDescriptionListeners from './listeners/accommodation-description'
import * as accommodationGalleryListeners from './listeners/accommodation-gallery'
import * as accommodationPracticalBlockListeners from './listeners/accommodation-practical-block'
import * as accommodationSkiBlockListeners from './listeners/accommodation-ski-block'
import * as accommodationNavListeners from './listeners/accommodation-nav'
import * as accommodationRecommendationListeners from './listeners/accommodation-recommendation'
import * as flightBusSelectorListeners from './listeners/flight-bus-selector'
import * as permissionSettingsListeners from './listeners/permission-settings'
import * as promotionImageListeners from './listeners/promotion-image'
import * as mainMenuListeners from './listeners/main-menu'
import * as loginStatusListeners from './listeners/login-status'
import typeChecker from './type-checker'

export function getEventFunction (name, track) {
  switch (name) {
    case 'c-form':
      if (typeChecker.isPayment(track)) return paymentsListeners.listenFormComponentEvents
      if (typeChecker.isRefund(track)) return refundsListeners.listenFormComponentEvents
      if (typeChecker.isLoginForm(track)) return loginFormListeners.listenFormComponentEvents
      if (typeChecker.isPermissionSettings(track)) return permissionSettingsListeners.listenFormComponentEvents
      return sharedListeners.listenFormComponentEvents

    case 'c-dropdown-multiple':
      if (typeChecker.isQuickSearchFilter(track)) return quickSearchListeners.listenDropdownMultipleEvents
      if (typeChecker.isSearchFilter(track)) return searchListeners.listenDropdownMultipleEvents
      if (typeChecker.isBookingGateFilter(track)) return bookingGateListeners.listenDropdownMultipleEvents
      if (typeChecker.isReviewsListComponent(track)) return reviewsListListeners.listenDropdownMultipleEvents
      return null

    case 'c-dropdown':
      if (typeChecker.isQuickSearchFilter(track)) return quickSearchListeners.listenDropdownEvents
      if (typeChecker.isSearchFilter(track)) return searchListeners.listenDropdownEvents
      if (typeChecker.isBookingGateFilter(track)) return bookingGateListeners.listenDropdownEvents
      if (typeChecker.isReviewsListComponent(track)) return reviewsListListeners.listenDropdownEvents
      return null

    case 'c-choice-list':
      if (typeChecker.isSearchFilter(track)) return searchListeners.listenChoiceListEvents
      if (typeChecker.isBookingStepsComponent(track)) return bookingListeners.listenChoiceListEvents
      if (typeChecker.isBookingStepsPreferences(track)) return bookingListeners.listenBookingPreferencesEvents
      return null

    case 'c-autocomplete':
      if (typeChecker.isQuickSearchFilter(track)) return quickSearchListeners.listenAutocompleteEvents
      if (typeChecker.isLiveSearchComponent(track)) return liveSearchListeners.listenLiveSearchEvents
      return null

    case 'c-collapse':
      if (typeChecker.isAccountBookingComponent(track)) return accountBookingListeners.listenCollapseEvents
      if (typeChecker.isFaqTopQuestionsComponent(track)) return faqListeners.listenCollapseEvents
      if (typeChecker.isProfileData(track)) return profileDataListeners.listenCollapseEvents
      if (typeChecker.isAccommodationRoomtypesComponent(track)) return accommodationRoomtypesListeners.listenCollapseEvents
      if (typeChecker.isAccommodationFacilitiesComponent(track)) return accommodationFacilitiesListeners.listenCollapseEvents
      if (typeChecker.isAccommodationMealplansComponent(track)) return accommodationMealplansListeners.listenCollapseEvents
      if (typeChecker.isAccommodationPracticalBlockComponent(track)) return accommodationPracticalBlockListeners.listenCollapseEvents
      if (typeChecker.isAccommodationSkiBlockComponent(track)) return accommodationSkiBlockListeners.listenCollapseEvents
      if (typeChecker.isAccommodationDescription(track)) return accommodationDescriptionListeners.listenCollapseEvents
      if (typeChecker.isBookingCarRental(track)) return bookingListeners.listenCollapseEvents
      if (typeChecker.isReviewsTeaserComponent(track)) return reviewsTeaserListeners.listenCollapseEvents
      return null

    case 'c-nav':
      if (typeChecker.isMainNavigation(track) || typeChecker.isMobileNavigation(track)) return navigationListeners.listenMainNavigationEvents
      if (typeChecker.isAccommodationNav(track)) return accommodationNavListeners.listenNavigationEvents
      return null

    case 'c-btn':
      if (typeChecker.isAccountBookingComponent(track)) return accountBookingListeners.listenButtonEvents
      if (typeChecker.isAccountBookingsComponent(track)) return accountBookingsListeners.listenButtonEvents
      if (typeChecker.isRebookingOptions(track)) return rebookingOptionsListeners.listenButtonEvents
      if (typeChecker.isPreferenceQuestionnaire(track)) return preferenceQuestionnaireListeners.listenButtonEvents
      if (typeChecker.isHeaderNavigation(track)) return navigationListeners.listenHeaderButtonEvents
      if (typeChecker.isProfileData(track)) return profileDataListeners.listenButtonEvents
      if (typeChecker.isBookingCarRental(track)) return bookingListeners.listenCarRentalButtonEvents
      if (typeChecker.isBookingOptionalAncillariesPopup(track)) return bookingListeners.listenBookingOptionalAncillariesPopup
      if (typeChecker.isBookingErrorModal(track)) return bookingListeners.listenBookingErrorEvents
      if (typeChecker.isPromotionImage(track)) return promotionImageListeners.listenButtonEvents
      if (typeChecker.isAccommodationDestination(track)) return accommodationDestinationListeners.listenButtonEvents
      if (typeChecker.isReviewsTeaserComponent(track)) return reviewsTeaserListeners.listenButtonEvents
      if (typeChecker.isAccommodationRoomtypesComponent(track)) return accommodationRoomtypesListeners.listenBtnEvents
      if (typeChecker.isAccommodationRecommendation(track)) return accommodationRecommendationListeners.listenBtnEvents
      if (typeChecker.isAccommodationTabGoBackBtn(track)) return accommodationTabsListeners.listenBtnGoBackEvents
      if (typeChecker.isPayment(track)) return paymentsListeners.listenButtonEvents
      return null

    case 'c-card':
      if (typeChecker.isAccountBookingsComponent(track)) return accountBookingsListeners.listenCardEvents
      return null
    case 'c-favorite-btn':
      return favoriteListeners.listenFavoriteEvents

    case 'c-tabs':
      if (typeChecker.isAccommodationTabsComponent(track)) return accommodationTabsListeners.listenTabsEvents
      return null

    case 'c-img':
      if (typeChecker.isHeaderNavigation(track)) return navigationListeners.listenHeaderLogoEvents
      return null

    case 'c-modal':
      if (typeChecker.isAccountBookingsComponent(track)) return accountBookingsListeners.listenModalEvents
      return null

    case 'c-modal-v2':
      if (typeChecker.isBookingOptionalAncillariesPopup(track)) return bookingListeners.listenBookingOptionalAncillariesPopup
      if (typeChecker.isReviewsTeaserPopup(track)) return reviewsTeaserListeners.listenReviewsTeaserPopup
      return null

    case 'c-slider':
      if (typeChecker.isAccommodationGalleryComponent(track)) return accommodationGalleryListeners.listenSliderEvents
      if (typeChecker.isAccommodationRecommendation(track)) return accommodationRecommendationListeners.listenSliderEvents
      return null

    case 'w-reviews-list':
      return reviewsListListeners.listenReviewsListEvents

    case 'w-reviews-teaser':
      return reviewsTeaserListeners.listenReviewsTeaserEvents

    case 'w-voucher-redeemer':
      return vouchersListeners.listenVoucherRedeemerWidgetEvents

    case 'w-payment':
      return paymentsListeners.listenPaymentWidgetEvents

    case 'w-search':
      return searchListeners.listenSearchWidgetEvents

    case 'w-search-map':
      return searchListeners.listenMapEvents

    case 'w-dates-filter':
      if (typeChecker.isQuickSearchFilter(track)) return quickSearchListeners.listenDateFilterWidgetEvents
      if (typeChecker.isSearchFilter(track)) return searchListeners.listenDateFilterWidgetEvents
      return null

    case 'w-participants-filter':
      if (typeChecker.isQuickSearchFilter(track)) return quickSearchListeners.listenParticipantsFilterWidgetEvents
      if (typeChecker.isSearchFilter(track)) return searchListeners.listenParticipantsFilterWidgetEvents
      if (typeChecker.isBookingGateFilter(track)) return bookingGateListeners.listenParticipantsFilterWidgetEvents
      return null

    case 'w-product-map':
      return productsListeners.listenProductMapEvents

    case 'w-static-content-data':
      if (typeChecker.isAccommodationContent(track)) return sharedListeners.listenAccommodationStaticContentDataWidgetEvents
      return null

    case 'w-booked-service-edit':
      return accountBookedServiceEditListeners.listenBookedServiceEditEvents

    case 'w-booking-car-rental':
      return bookingListeners.listenBookingCarRentalEvents

    case 'w-booking-choice-list':
      return bookingListeners.listenBookingChoiceListEvents

    case 'w-booking-comparison-table':
      return bookingListeners.listenBookingComparisonTableEvents

    case 'w-booking-luggage':
      return bookingListeners.listenBookingLuggageEvents

    case 'w-booking-redeemer':
    case 'w-booking-bundle':
      return bookingListeners.listenBookingItemEvents

    case 'w-booking-participants-form':
      return bookingListeners.listenBookingParticipantsFormEvents

    case 'w-booking-participants-badge':
      return bookingListeners.listenBookingParticipantsBadgeEvents

    case 'w-booking-error':
      return bookingListeners.listenBookingErrorEvents

    case 'w-booking-filterable-services':
      if (typeChecker.isSkiService(track)) return bookingListeners.listenBookingFilterableServiceSkiEvents
      if (!typeChecker.isSkiService(track)) return bookingListeners.listenBookingItemEvents
      return null

    case 'w-promoted-price':
      if (typeChecker.isAccommodationPromotedPrice(track)) return bookingGateListeners.listenAccommodationPromotedPriceWidgetEvents
      return null

    case 'w-recommended-acco-lister':
      if (typeChecker.isAccommodationRecommendation(track)) return accommodationRecommendationListeners.listenRecommendedAccoListerWidgetEvents
      return searchListeners.listenAccoListerWidgetEvents

    case 'w-booking-steps':
      return bookingListeners.listenBookingStepsWidgetEvents

    case 'w-destination-filter':
      if (typeChecker.isQuickSearchFilter(track)) return quickSearchListeners.listenDestinationMultipleEvents
      if (typeChecker.isSearchFilter(track)) return searchListeners.listenDestinationMultipleEvents
      return null

    case 'w-price-filter':
      return searchListeners.listenPriceFilterWidgetEvents

    case 'w-tabs-filter':
      if (typeChecker.isBookingGateFilter(track)) return bookingGateListeners.listenTabsFilterWidgetEvents
      return null

    case 'w-booking-gate':
      return bookingGateListeners.listenBookingGateEvents

    case 'w-refund':
      return refundsListeners.listenRefundRequestWidgetEvents

    case 'w-booking-cancellation-flow':
      return accountBookingListeners.listenBookingCancellationFlow

    case 'w-personal-details-edit':
      return personalDetailsEditListeners.listenPersonalDetailsEditWidgetEvents

    case 'w-excursions':
      return accountBookingListeners.listenExcursionsEvents

    case 'w-preference-questionnaire':
      return preferenceQuestionnaireListeners.listenButtonEvents

    case 'w-download-documents':
      return accountBookingListeners.listenDownloadDocumentsEvents

    case 'w-flight-bus-selector':
      return flightBusSelectorListeners.listenFlightBusSelectorEvents

    case 'w-booking-optional-ancillaries-popup':
      return bookingListeners.listenBookingOptionalAncillariesPopup

    case 'w-main-menu':
      return mainMenuListeners.listenMainMenuEvents

    case 'w-login-status':
      return loginStatusListeners.listenLoginStatusEvents

    case 'w-account-special-luggage':
      return accountBookingListeners.listenAccountSpecialLuggageEvents

    default:
      return null
  }
}
