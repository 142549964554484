import * as eventTypes from '../../../../js/document/event-types'
import pusher from '../pusher'
import { filtersSections, formatParticipantsData, filterAndMapSelectedData, filterAndMapSelectedDropdownData } from '../helpers/filters-config-convention-transform'
import context from '../context'
import { promotedPriceMapper } from '../mappers/prices/promoted-price-mapper'
import { clickFilterMapper } from '../mappers/filters-mapper'
import {
  participantsAllocatedMapper,
  closeBasketButtonMapper,
  flightChangedMapper,
  busChangedMapper,
  bookingGateSubmittedMapper,
  errorsThrownMapper,
  priceCellClickedMapper,
  priceTableViewedMapper,
  openJawModalMapper,
  openJawSwitchMapper,
  openStartBookingErrorModalMapper
} from '../mappers/booking-gate-mapper'

export function listenAccommodationPromotedPriceWidgetEvents (event) {
  event.on(eventTypes.promotedPriceEvents.PROMOTED_PRICE_DATA_LOADED, (data) => {
    const accommodationDataContext = context.getContext('accommodation')
    const mappedData = promotedPriceMapper(data, accommodationDataContext)
    pusher.pushDataObject(mappedData)
  })
}

export function listenDropdownEvents (event, track) {
  event.on('changedOptions', (data) => {
    data = filterAndMapSelectedDropdownData(data, track, filtersSections.BOOKING_GATE)
    const mappedData = clickFilterMapper(track, data, filtersSections.BOOKING_GATE)
    pusher.pushDataObject(mappedData)
  })
}

export function listenDropdownMultipleEvents (event, track) {
  event.on('submit', (data) => {
    data = filterAndMapSelectedData(data, track, filtersSections.BOOKING_GATE)
    const mappedData = clickFilterMapper(track, data, filtersSections.BOOKING_GATE)
    pusher.pushDataObject(mappedData)
  })
}

export function listenParticipantsFilterWidgetEvents (event, track) {
  event.on(eventTypes.participantsFilterEvents.PARTICIPANTS_FILTER__FILTER_APPLIED, (data) => {
    data = formatParticipantsData(data)
    const mappedData = clickFilterMapper(track, data, filtersSections.BOOKING_GATE)
    pusher.pushDataObject(mappedData)
  })
}

export function listenTabsFilterWidgetEvents (event, track) {
  event.on('tabChanged', (data) => {
    data = [data]
    const mappedData = clickFilterMapper(track, data, filtersSections.BOOKING_GATE)
    pusher.pushDataObject(mappedData)
  })
}

export function listenBookingGateEvents (event, track) {
  event.on(eventTypes.bookingGateEvents.BOOKING_GATE_PARTICIPANTS_ALLOCATED, (data) => {
    const accommodationDataContext = context.getContext('accommodation')
    const mappedData = participantsAllocatedMapper(data, accommodationDataContext)

    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.bookingGateEvents.BOOKING_GATE_CLOSE_BASKET_BUTTON_CLICKED, (data) => {
    const accommodationDataContext = context.getContext('accommodation')
    const mappedData = closeBasketButtonMapper(data, accommodationDataContext)

    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.bookingGateEvents.BOOKING_GATE_FLIGHT_CHANGED, (data) => {
    const mappedData = flightChangedMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.bookingGateEvents.BOOKING_GATE_BUS_CHANGED, (data) => {
    const mappedData = busChangedMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.bookingGateEvents.BOOKING_GATE_BOOKING_SUBMITTED, (data) => {
    const accommodationDataContext = context.getContext('accommodation')
    const mappedData = bookingGateSubmittedMapper(data, accommodationDataContext)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.bookingGateEvents.BOOKING_GATE_ERRORS_THROWN, (data) => {
    const accommodationDataContext = context.getContext('accommodation')
    const mappedData = errorsThrownMapper(data, accommodationDataContext)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.bookingGateEvents.BOOKING_GATE_PRICE_CELL_CLICKED, (data) => {
    const accommodationDataContext = context.getContext('accommodation')
    const mappedData = priceCellClickedMapper(data, accommodationDataContext)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.bookingGateEvents.BOOKING_GATE_PRICE_TABLE_VIEWED, (data) => {
    const accommodationDataContext = context.getContext('accommodation')
    const mappedData = priceTableViewedMapper(data, accommodationDataContext)
    pusher.pushDataObject(mappedData)
  })

  /* Open Jaw Modal */
  event.on(eventTypes.bookingGateEvents.BOOKING_GATE_OPEN_JAW_MODAL_OPEN, (data) => {
    const mappedData = openJawModalMapper(data, 'Pop-up shown', false)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.bookingGateEvents.BOOKING_GATE_OPEN_JAW_MODAL_SUBMIT, (data) => {
    const mappedData = openJawModalMapper(data, 'Ok', true)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.bookingGateEvents.BOOKING_GATE_OPEN_JAW_MODAL_CLOSE, (data) => {
    const mappedData = openJawModalMapper(data, 'Back', true)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.bookingGateEvents.BOOKING_GATE_OPEN_JAW_SWITCH, (data) => {
    const mappedData = openJawSwitchMapper(data, true)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.bookingGateEvents.BOOKING_GATE_OPEN_START_BOOKING_ERROR_MODAL, (data) => {
    const mappedData = openStartBookingErrorModalMapper(data)
    pusher.pushDataObject(mappedData)
  })
}
