import { PRODUCT_DIMENSIONS, CUSTOM_DIMENSIONS } from '../constants'
import { ga4Mapper } from './ga4-mapper'

export const participantsAllocatedMapper = (data, accoData) => {
  let dataObject = null
  const accoDataset = accoData ? accoData.dataset : null

  if (data && accoDataset) {
    dataObject = {
      event: 'eec.add',
      ecommerce: {
        currencyCode: _getCurrencyCode(data),
        add: {
          actionField: {
            list: 'participants allocated'
          },
          products: _mapProducts(accoDataset, data)
        }
      }
    }
  }
  return dataObject
}

export const priceCellClickedMapper = (data, accoData) => {
  let dataObject = null
  const accoDataset = accoData ? accoData.dataset : null

  if (data && accoDataset) {
    dataObject = {
      event: 'viewPrices',
      eventCategory: 'PDP-prices',
      eventAction: 'click',
      eventLabel: '',
      accoId: sanitizeString(accoDataset.wStaticContentDataId),
      accoName: sanitizeString(accoDataset.wStaticContentDataName),
      accoBrand: sanitizeString(accoDataset.wStaticContentDataBrand), // (new)
      accoCategory: sanitizeString(accoDataset.wStaticContentDataCategory),
      accoStars: sanitizeString(accoDataset.wStaticContentDataAccoStars),
      transport: sanitizeString(data.selectedValues ? data.selectedValues.TransportType : ''),
      boardType: sanitizeString(data.selectedValues ? data.selectedValues.Mealplan : ''),
      duration: sanitizeString(data.priceClickedData ? data.priceClickedData.duration : ''),
      rating: sanitizeString(accoDataset.wStaticContentDataRating),
      partyComposition: data.totalOccupation ? getPartyCompositionDescription(data.totalOccupation) : '',
      totalGuests: getTotalOfParticipants(data),
      departureDate: sanitizeDate(data.priceClickedData ? data.priceClickedData.departureDate : ''),
      entryPrice: sanitizeString(data.priceClickedData ? data.priceClickedData.price : ''),
      promotedPrice: data.isPromotedPriceOpened ? 'shown' : '',
      cityId: sanitizeString(accoDataset.wStaticContentDataLocationId),
      regionId: sanitizeString(accoDataset.wStaticContentDataAccoRegionId),
      countryId: sanitizeString(accoDataset.wStaticContentDataAccoCountryId)
    }
  }
  return dataObject
}

export const closeBasketButtonMapper = (data, accoData) => {
  let dataObject = null
  const accoDataset = accoData ? accoData.dataset : null

  if (data && accoDataset) {
    dataObject = {
      event: 'eec.remove',
      ecommerce: {
        currencyCode: _getCurrencyCode(data),
        remove: {
          actionField: {
            list: 'close basket button clicked'
          },
          products: _mapProducts(accoDataset, data)
        }
      }
    }

    dataObject.ecommerce.remove.products[0].unallocatedGuests = data.unallocatedOccupation ? data.unallocatedOccupation.total : 0
    dataObject.ecommerce.remove.products[0].unallocatedPartyComposition = data.unallocatedOccupation ? getPartyCompositionDescription(data.unallocatedOccupation) : ''
  }

  return dataObject
}

export const flightChangedMapper = (data) => {
  let dataObject = null

  if (data && data.flightInfo) {
    dataObject = _mapFlight(data.flightInfo)
    dataObject.event = data.direction + 'Flight'
  }

  return dataObject
}

export const busChangedMapper = (data) => {
  let dataObject = null

  if (data && data.busInfo) {
    dataObject = _mapBus(data.busInfo)
    dataObject.event = data.direction + 'Bus'
  }

  return dataObject
}

export const bookingGateSubmittedMapper = (data, accoData) => {
  let dataObject = null
  const accoDataset = accoData ? accoData.dataset : null

  if (data && accoDataset) {
    dataObject = {
      event: 'eec.submit',
      ecommerce: {
        currencyCode: _getCurrencyCode(data),
        add: {
          actionField: { list: accoData.originList },
          products: [{
            [PRODUCT_DIMENSIONS.id]: sanitizeString(accoDataset.wStaticContentDataId),
            [PRODUCT_DIMENSIONS.name]: sanitizeString(accoDataset.wStaticContentDataName),
            [PRODUCT_DIMENSIONS.brand]: sanitizeString(accoDataset.wStaticContentDataBrand),
            [PRODUCT_DIMENSIONS.category]: sanitizeString(accoDataset.wStaticContentDataCategory),
            [PRODUCT_DIMENSIONS.quantity]: data.allocatedPackages ? data.allocatedPackages.length : 0,
            [PRODUCT_DIMENSIONS.price]: data.totalPrice,
            [PRODUCT_DIMENSIONS.accoStars]: sanitizeString(accoDataset.wStaticContentDataAccoStars), // accoStars
            [PRODUCT_DIMENSIONS.locationId]: sanitizeString(accoDataset.wStaticContentDataLocationId), // locationId
            [PRODUCT_DIMENSIONS.transport]: sanitizeString(data.selectedValues ? data.selectedValues.TransportType : ''), // transport
            [PRODUCT_DIMENSIONS.composition]: data.totalOccupation ? getPartyCompositionDescription(data.totalOccupation) : '', // composition
            [PRODUCT_DIMENSIONS.badges]: sanitizeString(accoDataset.wStaticContentDataBadges), // badges
            [PRODUCT_DIMENSIONS.rating]: sanitizeString(accoDataset.wStaticContentDataRating), // rating
            [PRODUCT_DIMENSIONS.duration]: sanitizeString(data.priceClickedData ? data.priceClickedData.duration : ''), // duration
            [PRODUCT_DIMENSIONS.tripComposition]: data.totalOccupation ? getPartyCompositionDescription(data.totalOccupation) : '', // composition
            [PRODUCT_DIMENSIONS.daysForDeparture]: getDaysBeforeDeparture(data.priceClickedData ? data.priceClickedData.departureDate : ''), // days for departure
            [PRODUCT_DIMENSIONS.totalGuests]: getTotalOfParticipants(data), // totalguests
            [PRODUCT_DIMENSIONS.departureDate]: sanitizeDate(data.priceClickedData ? data.priceClickedData.departureDate : ''), // selected departuredate
            [PRODUCT_DIMENSIONS.roomType]: getRoomTypeDescription(data), // roomtype
            [PRODUCT_DIMENSIONS.boardType]: sanitizeString(data.selectedValues ? data.selectedValues.Mealplan : ''), // boardtype
            [PRODUCT_DIMENSIONS.originalDepartureDate]: data.originalDepartureDate, // original departuredate on the accodationpage
            [PRODUCT_DIMENSIONS.cityId]: sanitizeString(accoDataset.wStaticContentDataLocationId),
            [PRODUCT_DIMENSIONS.regionId]: sanitizeString(accoDataset.wStaticContentDataAccoRegionId),
            [PRODUCT_DIMENSIONS.countryId]: sanitizeString(accoDataset.wStaticContentDataAccoCountryId)

          }]
        }
      },
      [CUSTOM_DIMENSIONS.accoId]: sanitizeString(accoDataset.wStaticContentDataId),
      [CUSTOM_DIMENSIONS.accoName]: sanitizeString(accoDataset.wStaticContentDataName),
      [CUSTOM_DIMENSIONS.accoBrand]: sanitizeString(accoDataset.wStaticContentDataBrand),
      [CUSTOM_DIMENSIONS.accoCategory]: sanitizeString(accoDataset.wStaticContentDataCategory),
      [CUSTOM_DIMENSIONS.accoStars]: sanitizeString(accoDataset.wStaticContentDataAccoStars),
      [CUSTOM_DIMENSIONS.transport]: sanitizeString(data.selectedValues ? data.selectedValues.TransportType : ''),
      [CUSTOM_DIMENSIONS.boardType]: sanitizeString(data.selectedValues ? data.selectedValues.Mealplan : ''),
      [CUSTOM_DIMENSIONS.duration]: sanitizeString(data.priceClickedData ? data.priceClickedData.duration : ''),
      [CUSTOM_DIMENSIONS.rating]: sanitizeString(accoDataset.wStaticContentDataRating),
      [CUSTOM_DIMENSIONS.partyComposition]: data.totalOccupation ? getPartyCompositionDescription(data.totalOccupation) : '',
      [CUSTOM_DIMENSIONS.totalGuests]: getTotalOfParticipants(data),
      [CUSTOM_DIMENSIONS.departureDate]: sanitizeDate(data.priceClickedData ? data.priceClickedData.departureDate : ''),
      [CUSTOM_DIMENSIONS.originalDate]: data.originalDepartureDate,
      [CUSTOM_DIMENSIONS.entryPrice]: sanitizeString(data.priceClickedData ? data.priceClickedData.price : ''),
      [CUSTOM_DIMENSIONS.promotedPrice]: data.isPromotedPriceOpened ? 'shown' : '',
      [CUSTOM_DIMENSIONS.cityId]: sanitizeString(accoDataset.wStaticContentDataLocationId),
      [CUSTOM_DIMENSIONS.regionId]: sanitizeString(accoDataset.wStaticContentDataAccoRegionId),
      [CUSTOM_DIMENSIONS.countryId]: sanitizeString(accoDataset.wStaticContentDataAccoCountryId)
    }
  }

  return dataObject
}

export const errorsThrownMapper = (data, accoData) => {
  let errorsObject = null

  if (data && data.origin) {
    const accoDataset = accoData ? accoData.dataset : null

    errorsObject = {
      // Identify the specific acco affected.
      brand: sanitizeString(accoDataset.wStaticContentDataBrand),
      accoId: sanitizeString(accoDataset.wStaticContentDataId),
      // Identify the error (type of event that produced the error, associated human message and list of underlying errors descriptions).
      event: 'eec.ajax_error',
      origin: data.origin,
      message: data.message || null,
      errors: data.errors || null
    }
  }

  return errorsObject
}

export const priceTableViewedMapper = (data, accoData) => {
  let dataObject = null
  const accoDataset = accoData ? accoData.dataset : null

  if (data && accoDataset) {
    dataObject = {
      event: 'viewPrices',
      ecommerce: {
        currencyCode: _getCurrencyCode(data),
        add: {
          actionField: { list: accoData.originList },
          products: [{
            [PRODUCT_DIMENSIONS.id]: sanitizeString(accoDataset.wStaticContentDataId),
            [PRODUCT_DIMENSIONS.name]: sanitizeString(accoDataset.wStaticContentDataName),
            [PRODUCT_DIMENSIONS.brand]: sanitizeString(accoDataset.wStaticContentDataBrand),
            [PRODUCT_DIMENSIONS.category]: sanitizeString(accoDataset.wStaticContentDataCategory),
            [PRODUCT_DIMENSIONS.quantity]: data.allocatedPackages ? data.allocatedPackages.length : 0,
            [PRODUCT_DIMENSIONS.price]: data.totalPrice,
            [PRODUCT_DIMENSIONS.accoStars]: sanitizeString(accoDataset.wStaticContentDataAccoStars), // accoStars
            [PRODUCT_DIMENSIONS.locationId]: sanitizeString(accoDataset.wStaticContentDataLocationId), // locationId
            [PRODUCT_DIMENSIONS.transport]: sanitizeString(data.selectedValues ? data.selectedValues.TransportType : ''), // transport
            [PRODUCT_DIMENSIONS.composition]: data.totalOccupation ? getPartyCompositionDescription(data.totalOccupation) : '', // composition
            [PRODUCT_DIMENSIONS.badges]: sanitizeString(accoDataset.wStaticContentDataBadges), // badges
            [PRODUCT_DIMENSIONS.rating]: sanitizeString(accoDataset.wStaticContentDataRating), // rating
            [PRODUCT_DIMENSIONS.duration]: sanitizeString(data.priceClickedData ? data.priceClickedData.duration : ''), // duration
            [PRODUCT_DIMENSIONS.tripComposition]: data.totalOccupation ? getPartyCompositionDescription(data.totalOccupation) : '', // composition
            [PRODUCT_DIMENSIONS.daysForDeparture]: getDaysBeforeDeparture(data.priceClickedData ? data.priceClickedData.departureDate : ''), // days for departure
            [PRODUCT_DIMENSIONS.totalGuests]: getTotalOfParticipants(data), // totalguests
            [PRODUCT_DIMENSIONS.departureDate]: sanitizeDate(data.priceClickedData ? data.priceClickedData.departureDate : ''), // selected departuredate
            [PRODUCT_DIMENSIONS.roomType]: getRoomTypeDescription(data), // roomtype
            [PRODUCT_DIMENSIONS.boardType]: sanitizeString(data.selectedValues ? data.selectedValues.Mealplan : ''), // boardtype
            [PRODUCT_DIMENSIONS.cityId]: sanitizeString(accoDataset.wStaticContentDataLocationId),
            [PRODUCT_DIMENSIONS.regionId]: sanitizeString(accoDataset.wStaticContentDataAccoRegionId),
            [PRODUCT_DIMENSIONS.countryId]: sanitizeString(accoDataset.wStaticContentDataAccoCountryId)
          }]
        }
      },
      [CUSTOM_DIMENSIONS.accoId]: sanitizeString(accoDataset.wStaticContentDataId),
      [CUSTOM_DIMENSIONS.accoName]: sanitizeString(accoDataset.wStaticContentDataName),
      [CUSTOM_DIMENSIONS.accoBrand]: sanitizeString(accoDataset.wStaticContentDataBrand),
      [CUSTOM_DIMENSIONS.accoCategory]: sanitizeString(accoDataset.wStaticContentDataCategory),
      [CUSTOM_DIMENSIONS.accoStars]: sanitizeString(accoDataset.wStaticContentDataAccoStars),
      [CUSTOM_DIMENSIONS.transport]: sanitizeString(data.selectedValues ? data.selectedValues.TransportType : ''),
      [CUSTOM_DIMENSIONS.boardType]: sanitizeString(data.selectedValues ? data.selectedValues.Mealplan : ''),
      [CUSTOM_DIMENSIONS.duration]: sanitizeString(data.selectedValues && data.selectedValues.Duration ? data.selectedValues.Duration.join() : ''),
      [CUSTOM_DIMENSIONS.rating]: sanitizeString(accoDataset.wStaticContentDataRating),
      [CUSTOM_DIMENSIONS.partyComposition]: data.totalOccupation ? getPartyCompositionDescription(data.totalOccupation) : '',
      [CUSTOM_DIMENSIONS.totalGuests]: getTotalOfParticipants(data),
      [CUSTOM_DIMENSIONS.departureDate]: sanitizeDate(data.priceClickedData ? data.priceClickedData.departureDate : ''),
      [CUSTOM_DIMENSIONS.originalDate]: sanitizeString(data.originalDepartureDate ? data.originalDepartureDate : ''),
      [CUSTOM_DIMENSIONS.entryPrice]: sanitizeString(data.priceClickedData ? data.priceClickedData.price : ''),
      [CUSTOM_DIMENSIONS.promotedPrice]: data.isPromotedPriceOpened ? 'shown' : '',
      [CUSTOM_DIMENSIONS.cityId]: sanitizeString(accoDataset.wStaticContentDataLocationId),
      [CUSTOM_DIMENSIONS.regionId]: sanitizeString(accoDataset.wStaticContentDataAccoRegionId),
      [CUSTOM_DIMENSIONS.countryId]: sanitizeString(accoDataset.wStaticContentDataAccoCountryId)
    }
  }

  return dataObject
}

export const openJawModalMapper = (data, result, interaction) => {
  const dataObject = {
    event: 'Flight',
    description: 'Open Jaw pop-up',
    element: interaction ? 'element clicked' : 'element visible',
    result,
    interaction,
    type: `${data.departureAirport} - ${data.arrivalAirport}`
  }
  return dataObject
}

export const openJawSwitchMapper = (data, interaction) => {
  const dataObject = {
    event: 'Flight',
    description: 'Open Jaw switch',
    element: 'toggle',
    result: data.value ? 'On' : 'Off',
    interaction
  }
  return dataObject
}

export const openStartBookingErrorModalMapper = (data) => {
  const dataObject = ga4Mapper({
    event: 'error',
    description: 'error popup in basket',
    element: 'popup_shown',
    result: 'not possible to create a booking',
    interaction: false,
    // .toString() is required as the returned object will pass through JSON.stringify() and the exception
    // is the result of an error object from a catch block, therefore it is shown as a string but it is really
    // an object. Using .toString() we extract the message from the object while still allowing the exception
    // variable to be a string passed to the mapper, therfore maintaining it as generic as possible.
    type: data?.exception?.toString() || 'error'
  })

  // Extra properties to be sent to GA4.
  dataObject.accoId = data?.accommodationId

  return dataObject
}

function _getCurrencyCode (data) {
  return sanitizeString(data && data.priceClickedData && data.priceClickedData.currencyCode ? data.priceClickedData.currencyCode : '')
}

function _mapProducts (accoDataset, data) {
  return [{
    name: sanitizeString(accoDataset.wStaticContentDataName),
    id: sanitizeString(accoDataset.wStaticContentDataId),
    brand: sanitizeString(accoDataset.wStaticContentDataBrand),
    category: sanitizeString(accoDataset.wStaticContentDataCategory),
    quantity: data.allocatedPackages ? data.allocatedPackages.length : 0,
    accoStars: sanitizeString(accoDataset.wStaticContentDataAccoStars),
    locationId: sanitizeString(accoDataset.wStaticContentDataLocationId),
    badges: sanitizeString(accoDataset.wStaticContentDataBadges),
    rating: sanitizeString(accoDataset.wStaticContentDataRating),
    transport: sanitizeString(data.selectedValues ? data.selectedValues.TransportType : ''),
    boardType: sanitizeString(data.selectedValues ? data.selectedValues.Mealplan : ''),
    duration: sanitizeString(data.priceClickedData ? data.priceClickedData.duration : ''),
    departureDate: sanitizeDate(data.priceClickedData ? data.priceClickedData.departureDate : ''),
    totalGuests: getTotalOfParticipants(data),
    partyComposition: data.totalOccupation ? getPartyCompositionDescription(data.totalOccupation) : '',
    daysBeforeDeparture: getDaysBeforeDeparture(data.priceClickedData ? data.priceClickedData.departureDate : ''),
    price: data.priceClickedData ? parseFloat(data.priceClickedData.price) : '',
    roomType: getRoomTypeDescription(data),
    priceHighlighted: booleanToString(data.priceClickedData && (data.priceClickedData.isPriceHighlighted || false)),
    campaignId: data.campaignCode ?? '',
    cityId: sanitizeString(accoDataset.wStaticContentDataLocationId),
    regionId: sanitizeString(accoDataset.wStaticContentDataAccoRegionId),
    countryId: sanitizeString(accoDataset.wStaticContentDataAccoCountryId)
  }]
}

function _mapFlight (flightInfo) {
  if (flightInfo) {
    return {
      flightProvider: sanitizeString(flightInfo.airlineId),
      arrivalAirport: sanitizeString(flightInfo.arrivalAirportId),
      departureAirport: sanitizeString(flightInfo.departureAirportId),
      departureDate: flightInfo.departureDate ? getDateFromDateTime(flightInfo.departureDate.raw) : '',
      departureTime: flightInfo.isDepartureTimeSpecified === true ? sanitizeString(flightInfo.departureDate ? flightInfo.departureDate.formattedTime : '') : '',
      price: flightInfo.priceDiff ? flightInfo.priceDiff.raw : '',
      isDefaultOption: booleanToString(flightInfo.isDefaultOption)
    }
  } else {
    return ''
  }
}

function _mapBus (busInfo) {
  if (busInfo) {
    return {
      departureCity: sanitizeString(busInfo.departureCityName).trim(),
      arrivalCity: sanitizeString(busInfo.arrivalCityName).trim(),
      departureDate: busInfo.departureDate ? getDateFromDateTime(busInfo.departureDate.raw) : '',
      departureTime: sanitizeString(busInfo.departureDate ? busInfo.departureDate.formattedTime : ''),
      price: busInfo.priceDiff ? busInfo.priceDiff.raw : '',
      isDefaultOption: booleanToString(busInfo.isDefaultOption)
    }
  } else {
    return ''
  }
}

const booleanToString = (data) => {
  return data ? 'yes' : 'no'
}

const sanitizeString = (data) => {
  return data || ''
}

const sanitizeDate = (date) => {
  return sanitizeString(date ? date.replace(/-/g, '') : '')
}

const getTotalOfParticipants = (data) => {
  if (data.selectedValues) {
    return data.selectedValues.Participants ? data.selectedValues.Participants.reduce((sum, pc) => sum + pc.length, 0) : 0
  } else {
    return ''
  }
}

const getRoomTypeDescription = (data) => {
  return data.allocatedPackages ? data.allocatedPackages.map(pkg => pkg.roomName).join(' - ') : ''
}

const getPartyCompositionDescription = (occupation) => {
  if (occupation) {
    return `${occupation.adults || 0}a ${occupation.children || 0}c ${occupation.babies || 0}b`
  } else {
    return ''
  }
}

const getDaysBeforeDeparture = (departureDate) => {
  const oneDay = 24 * 60 * 60 * 1000 // hours * minutes * seconds * milliseconds
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const departureDateSplitted = departureDate.split('-')
  const departureDateAsDate = new Date(departureDateSplitted[0], departureDateSplitted[1] - 1, departureDateSplitted[2])

  return Math.round(Math.abs((departureDateAsDate - today) / oneDay))
}

const getDateFromDateTime = (dateTimeAsString) => {
  const dateTime = new Date(dateTimeAsString)

  let month = dateTime.getMonth() + 1

  if (month < 10) {
    month = '0' + month
  }

  let day = dateTime.getDate()

  if (day < 10) {
    day = '0' + day
  }

  return `${dateTime.getFullYear()}${month}${day}`
}
